import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { useDefaultData } from '~/hooks/DefaultData';
import { useOrderServiceBuilder } from '~/hooks/OrderServiceBuilder';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IFormData, IParams } from '~/models/Simulation/Steps';
import { useStep } from '~/pages/Simulation/Hooks/useStep';
import getValidationErros from '~/utils/getValidationsErrors';

import InputBirthdate from '../Components/InputBirthdate';
import InputPropertyType from '../Components/InputPropertyType';
import InputPropertyValue from '../Components/InputPropertyValue';
import InputRevenue from '../Components/InputRevenue';
import StepInfo from '../Components/StepInfo';

const StepsForm: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { orderServiceBuilder } = useOrderServiceBuilder();
  const {
    birthdate, step, setStep, totalSteps, setTotalSteps
  } = useStep();
  const { serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();
  const [allowSteps, setOnAllowSteps] = useState(false)

  useEffect(() => {
    if (
      serviceOrder.finalidade_id === 3
      || serviceOrder.finalidade_id === 4
      || serviceOrder.finalidade_id === 6
    ) {
      setTotalSteps(4);
      setDefaultData();
    } else {
      setTotalSteps(2);
    }
  }, [serviceOrder.finalidade_id, setTotalSteps, setDefaultData]);

  useEffect(() => {
    if (step === 5) {
      if (orderServiceBuilder?.builder_id && orderServiceBuilder?.bdi) {
        history.push(
          `${process.env.PUBLIC_URL}/simulador/resultado/nova-os/${orderServiceBuilder?.builder_id}/${orderServiceBuilder?.bdi}`
        );
      } else if (params.osId !== undefined) {
        history.push(
          `${process.env.PUBLIC_URL}/simulador/resultado/${params.osId}`
        );
      } else {
        history.push(`${process.env.PUBLIC_URL}/simulador/resultado/`);
      }
    }
  }, [step, setStep, history, orderServiceBuilder, params.osId]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          revenue: Yup.string().required('O valor da renda é obrigatório'),
          birthdate: Yup.string().required('Insira um um valor válido 🚫'),
          property_type: Yup.string().when('$property_type', {
            is: (propertyTypeCheck: boolean) => propertyTypeCheck,
            then: Yup.string().required('O tipo de imóvel é obrigatório'),
            otherwise: Yup.string(),
          }),
          property_price: Yup.string().when('$property_price', {
            is: (propertyPriceCheck: boolean) => propertyPriceCheck,
            then: Yup.string().required('O tipo de imóvel é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            property_type: totalSteps === 4,
            property_price: totalSteps === 4,
          },
        });

        if (birthdate) {
          if (orderServiceBuilder?.builder_id && orderServiceBuilder?.bdi) {
            history.push(
              `${process.env.PUBLIC_URL}/simulador/resultado/nova-os/${orderServiceBuilder?.builder_id}/${orderServiceBuilder?.bdi}`
            );
          } else if (params.osId !== undefined) {
            history.push(`/simulador/resultado/${params.osId}`);
          } else {
            history.push('/simulador/resultado/');
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [
      birthdate,
      history,
      orderServiceBuilder?.bdi,
      orderServiceBuilder?.builder_id,
      params.osId,
      totalSteps,
    ]
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <StepInfo />
        {step === 1 && <InputRevenue />}
        {step === 2 && <InputBirthdate />}
        {step === 3 && <InputPropertyType />}
        {step === 4 && <InputPropertyValue />}
      </Form>
    </>
  );
};

export default StepsForm;
