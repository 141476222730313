import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';

import logoHarvey from '~/assets/logos/logo-harvey.svg';
import { Background, Container, Content } from './styles';

interface IFormData {
  password: string;
  passwordConfirmation: string;
}

interface IParams {
  token: string;
  email: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setErrorConfirmPassword('');
      setErrorPassword('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object({
          password: Yup.string()
            .min(8, 'senha é muito curta')
            .max(30, 'senha é muito longa')
            .required('Informe uma nova senha'),
          passwordConfirmation: Yup.string()
            .min(8, 'senha é muito curta')
            .max(30, 'senha é muito longa')
            .required('Confirme a nova senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!!data.password.match(/^(?=.*[a-z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra minúscula');
        } else if (!!data.password.match(/^(?=.*[A-Z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra maiúscula');
        } else if (!!data.password.match(/^(?=.*[0-9])/) !== true) {
          setErrorPassword('Necessário ao menos um número');
        } else if (!!data.password.match(/^(?=.*[!@#%&])/) !== true) {
          setErrorPassword('Necessário ao menos uma caractere especial');
        } else if (data.password === data.passwordConfirmation) {
          const { password, passwordConfirmation } = data;

          const formData = {
            token: params.token.replace('token=', ''),
            password,
            password_confirmation: passwordConfirmation
          };

          await api.post('admins/reset-password', formData);

          Toast.fire({
            icon: 'success',
            title: 'Senha alterada com sucesso!',
          });

          history.push(`${process.env.PUBLIC_URL}/login`);
        } else {
          setErrorConfirmPassword('Senha não correspondente a informada');
        }
      } catch (error) {
        console.table(error)
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },

    [params.email, params.token]
  );

  return (
    <Container className="container-fluid">
      <div className="row justify-content-end">
        <Background className="col-lg-6 col-xl-6 col-xxl-7 d-none d-lg-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <div>
            <img
              src={logoHarvey}
              alt="logo-harvey"
              className="d-block mx-auto mb-5"
            />
            <h2 className="fw-light text-center company">
              <b className="fw-medium">Harvey</b> Construtor
            </h2>
            <hr className="line" />
            <p className="text-center">
              Aqui você encontrará todos os modelos de contratos e
              <br />
              Documentações Exclusivas que serão utilizados no
              <br />
              processos de financiamento habitacional.
            </p>
          </div>
        </Background>
        <Content className="bg-dark-1 col-lg-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div>
              <h1 className="h2 fw-semibold text-dark-gray">
                Criar uma nova senha
              </h1>
            </div>
            <div className="mb-5 py-5">
              <div className="mb-4">
                <label htmlFor="password" className="mb-2 fw-medium">
                  Nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Nova senha"
                  name="password"
                  className="input"
                />
                {errorPassword && (
                  <span className="small text-danger error">
                    {errorPassword}
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="mb-2 fw-medium"
                >
                  Confirmar nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Repetir senha"
                  name="passwordConfirmation"
                  className="input"
                />
                {errorConfirmPassword && (
                  <span className="small text-danger error">
                    {errorConfirmPassword}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-5">
              <button
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                Confirmar
              </button>
            </div>
          </Form>
        </Content>
      </div>
    </Container>
  );
};

export default Login;
