import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Icons
import { TbMapSearch } from "react-icons/tb";
import { VscServerEnvironment } from "react-icons/vsc";
import attachMoneyIcon from '~/assets/icons/attach_money.svg';
import checkBoxOutlineBlankIcon from '~/assets/icons/check_box_outline_blank.svg';
import holidayVillageIcon from '~/assets/icons/holiday_village.svg';
import idealAreaBuildIcon from '~/assets/icons/ideal-area-build.svg';

// Types
import { IStudyProps } from './types';

// Partials
import { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { IMStates } from '~/models/States';
import { Chat } from '~/pages/RealEstateProduct/Partials/Chat';
import api from '~/services/api';
import generateUrlBlob from '~/utils/generateUrlBlob';
import { ModalAI } from './Partials/ModalAI';

export function Study({ realEstateProduct, projectID, onRealEstateHouseAmount, onRealStateHouseidealArea, onRealStateHouseidealAreaBuild }: IStudyProps) {
  const history = useHistory();
  const [realEstateHouseAmount, setRealEstateHouseAmount] = useState(0);
  const [realStateHouseidealArea, setRealStateHouseidealArea] = useState(0);
  const [realStateHouseidealAreaBuild, setRealStateHouseidealAreaBuild] = useState(0);
  const [states, setStates] = useState<IMStates[]>([])
  // @ts-ignore
  const [realProductState, setRealProductState] = useState<Partial<IMStates> | null>(null)
  const [stateModalOn, setStateModalOn] = useState<boolean>(false)
  const [statePedding, setStatePedding] = useState<boolean>(false)
  const [sheetExcelPedding, setSheetExcelPedding] = useState<boolean>(false)

  // AUX Variables
  const AMOUNT_BY_METERS = useMemo(() => {
    const HOUSES_AMOUNT_BY_VGV = realEstateProduct?.houses?.reduce(
      (current, a) => {
        const t =
          Number(current.vgv || 0) + Number(a.vgv);

        return {
          vgv: t,
        };
      },
      { vgv: 0 }
    ).vgv || 1
    const HOUSES_AMOUNT_AREA_LENGTH = realEstateProduct?.houses?.reduce(
      (current, a) => {
        const t =
          Number(current.house_area || 0) +
          Number(a.house_area);

        return {
          house_area: t,
        };
      },
      { house_area: 0 }
    ).house_area || 1
    const HOUSES_BY_METERS_RAW = HOUSES_AMOUNT_BY_VGV / HOUSES_AMOUNT_AREA_LENGTH

    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(HOUSES_BY_METERS_RAW)

  }, [realEstateProduct])
  // REF's
  const STATE_ELEMENT_REF = useRef<any>()

  // MOUNT THE range LIST TO GET VGV MEDIA
  useEffect(() => {
    if (!realEstateProduct?.houses) return;

    const rangeAmounts: {
      [key: number]: {
        vgv: number;
        house_area: number;
        terrain_area: number;
      }[];
    } = {};

    // MOUT THE RANGE ESTRUCTURE
    for (let i = 0; i < realEstateProduct.houses.length || 0; i++) {
      const amountRaw = String(
        parseFloat(realEstateProduct.houses[i].vgv.toString() || '0')
      );

      const rangerHouse = {
        vgv: realEstateProduct.houses[i].vgv,
        house_area: realEstateProduct.houses[i].house_area,
        terrain_area: realEstateProduct.houses[i].terrain_area,
      };

      if (amountRaw.length - 1 === 3) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 4) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '0000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '9999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 5) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '00000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '99999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 6) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '000000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '999999');

        if (
          rangeAmounts[rangerIndex] &&
          rangerHouse.vgv >= parseFloat(rangerIndex.toString()) &&
          rangerHouse.vgv <= parseFloat(rangerIndexMax.toString())
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 7) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '0000000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '9999999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      }
    }

    let f: {
      vgv: number;
      house_area: number;
      terrain_area: number;
    }[] = [];

    // const k = Object.keys(rangeAmounts) as unknown as number[];

    // console.table(k)
    console.table(rangeAmounts)

    // k.fo
    // for (let j = 0; j < k.length; j++) {
    //   if (rangeAmounts[k[j]].length <= 1) continue;

    //   if (f.length <= 0) {
    //     f.push();
    //     continue;
    //   }

    //   if (f && rangeAmounts[k[j]].length > f.length) {
    //     f = rangeAmounts[k[j]];
    //   } else if (j >= 1 && f && rangeAmounts[k[j - 1]].length > f.length) {
    //     f = rangeAmounts[k[j]];
    //   }
    // }

    Object.entries(rangeAmounts).forEach((entry) => entry[1].forEach(amounts => f.push(amounts)))

    console.table(rangeAmounts)
    console.table(f)

    if (f && f.length >= 2) {
      const houseAmount =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.vgv) + Number(b.vgv);
            return { vgv: v };
          },
          { vgv: 0 }
        ).vgv || 1) / f.length;

      const idealArea =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.house_area) + Number(b.house_area);
            return { house_area: v };
          },
          { house_area: 0 }
        ).house_area || 1) / f.length;

      const idealAreaBuild =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.terrain_area) + Number(b.terrain_area);
            return { terrain_area: v };
          },
          { terrain_area: 0 }
        ).terrain_area || 1) / f.length;

      console.log('Range Amount Media: ', houseAmount);
      console.log('Range Ideal Area Media: ', idealArea);
      console.log('Range ideal Area Build Media: ', idealAreaBuild);

      handlerOnRealEstateHouseAmount(houseAmount);
      handlerOnRealStateHouseidealArea(idealArea);
      handlerOnRealStateHouseidealAreaBuild(idealAreaBuild);

      sessionStorage.setItem('REP@BUILD_DATA', JSON.stringify({
        houseAmount,
        idealArea,
        idealAreaBuild,
        AMOUNT_BY_METERS,
        VGV: rangeAmounts
      }))
    }
  }, [realEstateProduct]);

  useEffect(() => {
    if (states.length > 0) return;

    api.get<IMStates[]>(`${process.env.REACT_APP_API_URL}/builders/states`)
      .then(resolver => setStates(resolver.data))
      .catch(rejects => Swal.fire({
        text: 'Não foi possivel obter os estados',
        icon: 'warning'
      }))
  }, [])

  useEffect(() => {
    setRealProductState({
      ...realEstateProduct?.state,
      id: realEstateProduct?.state_id || -1
    })
  }, [realEstateProduct])

  function handlerOnRealEstateHouseAmount(value: number = 0) {
    try {
      setRealEstateHouseAmount(value);
      onRealEstateHouseAmount(value);
    } catch (error) {
      // do anything
    }
  }

  function handlerOnRealStateHouseidealArea(value: number = 0) {
    try {
      onRealStateHouseidealArea(value);
      setRealStateHouseidealArea(value);
    } catch (error) {
      // do anything
    }
  }

  function handlerOnRealStateHouseidealAreaBuild(value: number = 0) {
    try {
      onRealStateHouseidealAreaBuild(value);
      setRealStateHouseidealAreaBuild(value);
    } catch (error) {
      // do anything
    }
  }

  async function handlerGetInvestorSpreadSheet() {
    try {
      setSheetExcelPedding(true)
      const response = await api
        .get(
          `${process.env.REACT_APP_API_URL}/builders/real-estate-products/${realEstateProduct?.id}/investor-spreadsheet-excel/${realStateHouseidealAreaBuild}`,
          {
            responseType: 'blob',
          }
        );

      const FILE_URL = generateUrlBlob(response)

      const A = document.createElement('a')

      A.setAttribute('download', `rep-investor-spreadsheet.xls`)
      A.setAttribute('href', FILE_URL)
      A.click()

      setTimeout(() => {
        URL.revokeObjectURL(FILE_URL);
        A.remove();
        setSheetExcelPedding(false)
      }, 1000);
    } catch (error: any) {
      setSheetExcelPedding(false)
      console.log((error as AxiosError).response?.data)
    }
  }

  const handlerSetPickStateID = useCallback((stateID = -1) => {
    try {
      setStatePedding(true);

      if (stateID && stateID?.id <= 0) {
        setStatePedding(false)
        setRealProductState(() => ({
          id: -1
        }))
        throw new Error('The state iD is not valid');
      }

      api.put(`${process.env.REACT_APP_API_URL}/builders/real-estate-products/${realEstateProduct?.id || -1}`, {
        state_id: stateID
      }).then(_r => {
        setStateModalOn(false)
        setStatePedding(false)

        setRealProductState(() => ({
          id: stateID
        }))
      })
        .catch(_d => {
          Swal.fire({
            icon: 'warning',
            text: 'Não foi possivel salvar o estado'
          })
          setStatePedding(false)
        })
    } catch (error) {
      setStatePedding(false)
      // do anything
    }
  }, [realProductState, realEstateProduct])

  return (
    <div className="relative w-full flex flex-col items-start justify-start gap-[2.5rem] bg-[#39A887] rounded-[0.937rem] p-[1rem] md:p-[1.875rem] md:pt-[1.25rem]">

      <div className="w-full flex flex-row flex-nowrap justify-between text-white leading-normal">
        <div className="flex flex-col">
          <span className="font-[400] text-[1.5rem] max-sm:text-[0.875rem]">
            Resultados do estudo
          </span>
          <span className="font-bold text-[1.625rem] max-sm:text-[0.875rem]">
            imobiliário ideal e indicador
          </span>
        </div>
        <div
          data-showme={stateModalOn}
          className='absolute
            h-screen
            hidden
            data-[showme=true]:flex
            flex-row items-center justify-center
          '
          style={{
            width: '100vw',
            left: 'calc(-10vw)',
            top: 'calc(-40vh)',
            margin: '0 auto'
          }}
        >
          <div className='max-sm:w-[90%] max-sm:h-1/2 
              md:w-1/3 md:h-1/3
              bg-white rounded-xl border-[1px] border-gray-200
              flex flex-col gap-4 justify-between
              shadow-2xl
              p-8
            '
          >
            <div className="flex flex-col gap-[0.812rem]">
              <span className="text-[#707070] font-semibold text-[0.875rem]">
                Estado do empreendimento
              </span>

              <select
                disabled={statePedding}
                ref={STATE_ELEMENT_REF}
                name="state_id"
                id="state_id"
                className="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
                style={{
                  borderBottom: '0.081rem solid #B8B9BA',
                }}
              // onChange={(event) => handlerSetPickStateID(parseInt(event.target.value || '-1',10))}
              >
                <option value={-1} selected>Selecione um estado</option>

                {
                  states.map(state => (<option value={state.id}>({state.sigla}){" "}{state.nome}</option>))
                }
              </select>
            </div>
            <button
              disabled={statePedding}
              type='button'
              className='w-full h-16 rounded-xl bg-[#39a887] text-white font-semibold flex flex-row items-center justify-center disabled:cursor-not-allowed'
              onClick={() => handlerSetPickStateID(parseInt(STATE_ELEMENT_REF.current.selectedOptions[0].value || '-1', 10))}
            >
              {
                !statePedding ? 'Salvar' : (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )
              }

            </button>
            <button
              disabled={statePedding}
              type='button'
              className='w-full h-16 rounded-xl bg-[#d1ebe2] text-gray-700 font-semibold flex flex-row items-center justify-center disabled:cursor-not-allowed'
              onClick={() => setStateModalOn(false)}
            >
              {
                !statePedding ? 'Fechar' : (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )
              }

            </button>
          </div>
        </div>
        <div className='flex-1 flex flex-row items-center justify-end gap-4'>
          {
            // TODO - SHOULD BE REVIEWED TO DEBUG
          }
          {/*<NavLink
            title={!sheetExcelPedding ? 'Baixar planilha' : 'Aguarde...'}
            data-showme={(realProductState && realProductState.id) && realProductState?.id >= 1 && true}
            data-ispedding={sheetExcelPedding}
            className='data-[showme=true]:flex hidden cursor-pointer 
              data-[ispedding=true]:opacity-50 data-[ispedding=true]:animate-pulse
              data-[ispedding=true]:cursor-not-allowed
              data-[ispedding=true]:pointer-events-none
              transition-all duration-[0.38s]
            '
            to={'../investidor'}
          >
            <RiFileExcel2Fill size={24} />
          </NavLink>*/}
          {/* <span 
            title={!sheetExcelPedding ? 'Baixar planilha' : 'Aguarde...'}
            data-showme={(realProductState && realProductState.id) && realProductState?.id >= 1 && true}
            data-ispedding={sheetExcelPedding}
            className='data-[showme=true]:flex hidden cursor-pointer 
              data-[ispedding=true]:opacity-50 data-[ispedding=true]:animate-pulse
              data-[ispedding=true]:cursor-not-allowed
              data-[ispedding=true]:pointer-events-none
              transition-all duration-[0.38s]
            '
            onClick={handlerGetInvestorSpreadSheet}
          >
            <RiFileExcel2Fill size={24} />
          </span> */}

          <Chat />

          <span
            data-showme={((realProductState?.id && realProductState?.id <= -1) && !stateModalOn) && true}
            className='data-[showme=true]:flex hidden cursor-pointer'
            title='Cadastrar estado de origem'
            onClick={() => setStateModalOn(!stateModalOn)}
          >
            <TbMapSearch size={24} />
          </span>

          {
            realEstateHouseAmount > 0 && realStateHouseidealArea > 0 && realStateHouseidealAreaBuild > 0 && realEstateProduct && (
              <Link to={`/produtos-imobiliario/estudo/${realEstateProduct?.id || -1}`} className='-mt-2' title='Ver resumo' >
                <VscServerEnvironment size={24} className='' />
              </Link>
            )
          }

          <ModalAI projectData={realEstateProduct} rep_id={realEstateProduct?.id || -1} />
        </div>
      </div>

      <div className="w-full flex md:flex-row md:flex-nowrap md:items-center md:justify-between flex-col items-end justify-start gap-[1.25rem] md:gap-0">
        <div className="flex-1 md:max-w-[39.753rem] w-full flex flex-col gap-[0.625rem] p-[1rem] bg-white rounded-[0.937rem]">
          <span className="font-bold leading-normal text-[1.125rem]">
            Maturidade do condomínio
          </span>

          <div className="flex flex-row flex-nowrap items-center justify-between gap-[1.25rem]">
            <div className="flex-1 h-[1.2rem] rounded-[6.25rem] bg-[#F1F1F1] flex flex-row items-center overflow-hidden">
              <span
                className={`flex flex-row items-center justify-center bg-[#A4DECD] w-full h-full rounded-[6.25rem] text-white
                    text-[0.8rem] select-none cursor-default transition-all duration-[0.3s]`}
                style={{
                  width: `${realEstateProduct?.built_houses &&
                      realEstateProduct?.quantity_terrains
                      ? ((realEstateProduct?.built_houses || 1) /
                        (realEstateProduct?.quantity_terrains || 1)) *
                      100
                      : 0
                    }%
                        `,
                }}
              ></span>
            </div>
            <span className="text-[#414142] text-[1.125rem] font-semibold leading-normal cursor-default select-none">
              {
                // @ts-ignore
                realEstateProduct?.built_houses > 0 &&
                  realEstateProduct?.quantity_terrains &&
                  realEstateProduct?.quantity_terrains > 0
                  ? `${parseInt(
                    `${((realEstateProduct?.built_houses || 1) /
                      (realEstateProduct?.quantity_terrains || 1)) *
                    100
                    }`,
                    10
                  )}%`
                  : '0%'
              }
            </span>
          </div>
        </div>
        <button
          type="button"
          onClick={() =>
            history.push(`/produtos-imobiliario/lotes/${projectID}`)
          }
          className="flex-1 md:max-w-[18.125rem]
                w-[9.687rem] h-[3.375rem]
                flex flex-col items-center
                justify-center gap-[0.625rem]
                p-[0.625rem] bg-white
                hover:opacity-80 active:opacity-80
                transition-all duration-[0.3s]
                rounded-[0.937rem] hover:shadow-md
                active:shadow-md"
        >
          <span className="font-bold leading-normal text-[0.75rem] text-[#39A887]">
            Ver lotes carregados
          </span>
        </button>
      </div>

      <div className="w-full flex flex-col md:flex-row md:flex-nowrap items-start justify-start md:gap-[2.5rem] gap-[1.25rem]">
        <div
          className="
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]
            bg-IdealArea
          "
        >
          <span className="flex flex-row items-center justify-center  w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={idealAreaBuildIcon} />
          </span>
          <span className="w-full h-full  flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Área do terreno ideal</span>
            <span className="text-[#707070]">
              {
                // realEstateProduct
              }
              A partir de{' '}
              {realStateHouseidealArea.toLocaleString('pt-BR', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 2,
              })}m²
              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1
            flex
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]  
            bg-IdealAreaBuild"
        >
          <span className="flex flex-row items-center justify-center w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={checkBoxOutlineBlankIcon} />
          </span>
          <span className="w-full h-full  flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Área construída ideal</span>
            <span className="text-[#707070]">
              A partir de{' '}
              {
                realStateHouseidealAreaBuild.toLocaleString('pt-BR', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 2,
                }) + 'm²'
              }

              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem] 
            bg-AverageLandValue"
        >
          <span className="flex flex-row items-center justify-center  w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={attachMoneyIcon} />
          </span>
          <span className="w-full h-full flex flex-col  font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Valor médio de venda da casa</span>
            <span className="text-[#707070]">
              A partir de{' '}
              {
                Intl
                  .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                  .format(realEstateHouseAmount)
              }
              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]
            bg-AverageHouseValue"
        >
          <span className="flex flex-row items-center justify-center w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={holidayVillageIcon} />
          </span>
          <span className="w-full h-full flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Valor médio de venda do imóvel por m²</span>
            <span className="text-[#707070]">
              {
                AMOUNT_BY_METERS + '/m²'
              }

              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>
      </div>

      <div className="w-full flex md:flex-row md:flex-nowrap flex-col items-center justify-center md:items-center md:justify-between p-[1.25rem] bg-white rounded-[0.937rem] max-sm:gap-[1rem]">
        <span className="font-semibold leading-normal md:text-[1.125rem] max-sm:text-[1rem] text-center cursor-default select-none">
          Análise das Casas
        </span>

        <button
          onClick={() =>
            history.push(`/produtos-imobiliario/compare/${projectID}`)
          }
          type="button"
          className="w-[9.375rem] h-[3.375rem]
                rounded-[0.937rem] bg-[#39A887]
                hover:opacity-80 active:opacity-80
                transition-all duration-[0.3s]
                text-white
                flex flex-row flex-nowrap
                items-center justify-center
                shadow-sm
              "
        >
          <span className="font-bold">Veja análise</span>
        </button>
      </div>
    </div>
  );
};
