import { useEffect, useState } from "react";

// Icons
import { RiMoneyDollarBoxFill } from "react-icons/ri";

// Types
import { IRealEstateDevelopmentProps } from "./types";

// Models
import { IServiceOrderRealEstateDevlopment } from "~/models/ServiceOrder";

// Contexts
import { useServiceOrder } from "~/hooks/ServiceOrder";

// Styles
import "./styles.css";

// Utils
import formatCurrency from "~/utils/formatCurrency";

// Services
import api from "~/services/api";

export function RealEstateDevelopments(_props: IRealEstateDevelopmentProps) {
    const [realEstateDevlopment, setRealEstateDevlopment] = useState<IServiceOrderRealEstateDevlopment | null>(null);

    const { serviceOrder } = useServiceOrder()

    useEffect(() => {
        api.get<{ real_estate_development: IServiceOrderRealEstateDevlopment }>(`builders/service-orders/${serviceOrder?.id || -1}/full-data`)
            .then(resolved => {
                setRealEstateDevlopment(resolved.data.real_estate_development || {})
            })
    }, [])

    return (
        <div className="general-container" id="red-container">
            <h1 className="general-text-bold red-titles">
                Incorporação imobiliária
            </h1>

            <ul id="red-ul">
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        Valor da venda do imóvel de acordo com o mercado
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {formatCurrency(realEstateDevlopment?.sale_price || 0)}
                    </span>
                </li>
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        Valor de custo do imóvel com financiamento e taxas
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {formatCurrency(realEstateDevlopment?.cost_value || 0)}
                        <span className="li-footer-text-tag">+ Taxas</span>
                    </span>
                </li>
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        O investimento inicial com taxas para  executar o financiamento
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {formatCurrency(realEstateDevlopment?.pre_sales || 0)}
                    </span>
                </li>
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        Lucro na incorporação imobiliária total
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {formatCurrency(realEstateDevlopment?.profit || 0)}
                    </span>
                </li>
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        Com a construção e venda dessa casa sua rentabilidade anual será
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {(realEstateDevlopment?.annual_profitability || 0).toLocaleString('pt-BR', {
                            style: 'unit',
                            unit: 'percent'
                        })}
                    </span>
                </li>
                <li className="red-li">
                    <span className="red-li-header-text general-text-label">
                        Com a construção e venda dessa casa sua rentabilidade mensal será
                    </span>
                    <span className="red-li-footer-text general-text-bold">
                        {(realEstateDevlopment?.monthly_profitability || 0).toLocaleString('pt-BR', {
                            style: 'unit',
                            unit: 'percent'
                        })}
                    </span>
                </li>
            </ul>

            <div id="red-footer">
                <span className="general-text-bold red-titles" id="red-footer-title">
                    Tempo e Lucro

                    <RiMoneyDollarBoxFill color="#1CB978" />
                </span>

                <div id="red-footer-content">
                    <div className="general-box">
                        <span className="general-text-label">Vender na planta:</span>
                        {
                            realEstateDevlopment && realEstateDevlopment.time_vs_profit.map((amount, index) => (
                                <span key={index} className="general-text-label">Vender pós {amount.time} mese(s)</span>
                            ))
                        }
                    </div>
                    <div className="general-box">
                        <span className="general-text-bold">
                            {
                                formatCurrency(realEstateDevlopment?.pre_sales || 0)
                            }
                        </span>
                        {
                            realEstateDevlopment && realEstateDevlopment.time_vs_profit.map((amount, index) => (
                                <span key={index} className="general-text-bold">{formatCurrency(amount.profit)}</span>
                            ))
                        }
                    </div>
                    <div className="general-box">
                        <span className="general-text-bold">
                            {
                                formatCurrency(realEstateDevlopment?.pre_sales || 0)
                            }
                        </span>
                        {
                            realEstateDevlopment && realEstateDevlopment.time_vs_profit.map((amount, index) => (
                                <span key={index} className="general-text-bold">{formatCurrency(amount.profit / amount.time)}/ mês</span>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}