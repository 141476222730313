import { ComponentType, FC, useEffect, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  useHistory
} from 'react-router-dom';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Pages
import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';
import { getCookie } from '~/utils/cookeis';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

export const RouteComponent: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user, token } = useAuthContext();
  const history = useHistory()

  // AUX Variables
  const COOKIE_TOKEN = getCookie('@HarveyBuilder:token');

  const Layout = useMemo(() => {
    return isPrivate ? Default : Auth;
  }, [isPrivate]);

  useEffect(() => {
    // if (!COOKIE_TOKEN && isPrivate) history.replace('login');
  }, [])

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location, ...rest }) => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};
