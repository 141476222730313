import useFormIntegration from "kemis-library/hooks/form";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Controller } from 'react-hook-form';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

// Types
import { IACProps } from "./type";

// Schemas
import { SCFormAC } from "../../schemas/index";

// Services
import api from "~/services/api";

export function AC(props: IACProps) {
    const { osID } = useParams() as any
    const { register, handleSubmit, formState, control, getValues, setValue, reset } = useFormIntegration<Zod.infer<typeof SCFormAC>>(props.defaultData || {}, SCFormAC);

    async function onSubmit(data: Partial<Zod.infer<typeof SCFormAC>>) {
        try {
            console.table(data)

            if (!props?.id) throw new Error("The OS ID is not available");

            const response = await api.post(`builders/${props?.id}/complementary-data`, data)

            if (!response || response.status !== 201) throw new Error("Não foi possivel concluir o proceso");

            if (!props.allowFlowCB || typeof props.allowFlowCB !== 'function') throw new Error();

            props.allowFlowCB(true)
        } catch (error: any) {
            Swal.fire({
                icon: 'warning',
                text: error?.message || 'Houve um problema com o processo'
            })
        }
    }

    return <>
        <form
            onSubmit={handleSubmit(onSubmit, (r) => console.table(r))}
            // onSubmit={onSubmite}
            name="form-acquisition-build"
            id="form-acquisition-build"
            autoComplete="off"
            className="w-full h-auto flex max-sm:flex-col flex-row flex-wrap gap-8"
        >
            <span className="w-full h-auto flex pb-3 border-b-[2px] borde-gray-100">
                <h3 className="font-semibold text-xl select-none cursor-default">
                    Questionário para Aquisição + construção
                </h3>
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-0 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor do terreno</label>
                <span data-haserrors={formState?.errors?.terrain_amount?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.terrain_amount?.message}</span>
                <Controller
                    control={control}
                    name="terrain_amount"
                    render={({
                        field,
                    }) => (
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('terrain_amount', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Saldo devedor do terreno</label>
                <span data-haserrors={formState?.errors?.land_outstanding_balance?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.land_outstanding_balance?.message}</span>
                <Controller
                    control={control}
                    name='land_outstanding_balance'
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('land_outstanding_balance', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor total de obra (estimado)</label>
                <span data-haserrors={formState?.errors?.amount_build_estimated?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.amount_build_estimated?.message}</span>
                <Controller
                    control={control}
                    name='amount_build_estimated'
                    render={({
                        field,
                    }) => (
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('amount_build_estimated', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor do imóvel pronto para compra e venda (estimado)</label>
                <span data-haserrors={formState?.errors?.property_value_estimated?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.property_value_estimated?.message}</span>
                <Controller
                    control={control}
                    name="property_value_estimated"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('property_value_estimated', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor=""> Quanto ainda possui em recursos próprios ?</label>
                <span data-haserrors={formState?.errors?.how_much_resources?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.how_much_resources?.message}</span>
                <Controller
                    control={control}
                    name='how_much_resources'
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('how_much_resources', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Renda mensal formal?</label>
                <span data-haserrors={formState?.errors?.formal_monthly_income?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.formal_monthly_income?.message}</span>
                <Controller
                    control={control}
                    name="formal_monthly_income"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('formal_monthly_income', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Renda mensal informal?</label>
                <span data-haserrors={formState?.errors?.unformal_monthly_income?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.unformal_monthly_income?.message}</span>
                <Controller
                    control={control}
                    name="unformal_monthly_income"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('unformal_monthly_income', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor=""> Cidade do terreno</label>
                <span data-haserrors={formState?.errors?.city_of_terrain?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.city_of_terrain?.message}</span>
                <Controller
                    control={control}
                    name='city_of_terrain'
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o nome da cidade"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Qual nome do Condomínio?</label>
                <span data-haserrors={formState?.errors?.name_of_build?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.name_of_build?.message}</span>
                <Controller
                    control={control}
                    name='name_of_build'
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o nome do condominio"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Endereço completo do terreno com CEP</label>
                <span data-haserrors={formState?.errors?.address_terrain?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.address_terrain?.message}</span>
                <Controller
                    control={control}
                    name="address_terrain"
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o endereço do condominio"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>
        </form>
        <button type="submit"
            form="form-acquisition-build"
            className="max-sm:w-full 
                w-64 px-4 py-2
                rounded-lg
                text-white font-semibold 
                bg-[#39a887] hover:bg-[#2c8168] hover:active:bg-[#236652]
                transition-colors
                duration-[0.15s]
                flex flex-row items-center justify-center
                mt-12
            "
        >
            continuar
        </button>
    </>
}