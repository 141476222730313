import useFormIntegration from "kemis-library/hooks/form";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Controller } from 'react-hook-form';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";


// Types
import { ICTProps } from "./types";

// Schemas
import { SCFormCT } from "../../schemas/index";

// Services
import api from "~/services/api";

export function CT(props: ICTProps) {
    const { osID } = useParams() as any
    const { register, handleSubmit, formState, control, getValues, setValue, watch } = useFormIntegration<Zod.infer<typeof SCFormCT>>(props.defaultData || {}, SCFormCT);

    // AUX Variables
    const INPUT_has_rgi_WATCH = watch('has_rgi')
    const INPUT_approval_property_WATCH = watch('approval_property')
    const INPUT_terrain_or_build_WATCH = watch('terrain_or_build')

    async function onSubmit(data: Partial<Zod.infer<typeof SCFormCT>>) {
        try {
            if (!props?.id) throw new Error("The OS ID is not available");

            const response = await api.post(`builders/${props?.id}/complementary-data`, data)

            if (!response || response.status !== 201) throw new Error("Não foi possivel concluir o proceso");

            if (!props.allowFlowCB || typeof props.allowFlowCB !== 'function') throw new Error();

            props.allowFlowCB(true)
        } catch (error: any) {
            Swal.fire({
                icon: 'warning',
                text: error?.message || 'Houve um problema com o processo'
            })
        }
    }

    return <>
        <form
            onSubmit={handleSubmit(onSubmit, (r) => console.table(r))}
            // onSubmit={onSubmite}
            name="form-acquisition-build"
            id="form-acquisition-build"
            autoComplete="off"
            className="w-full h-auto flex max-sm:flex-col flex-row flex-wrap gap-8"
        >
            <span className="w-full h-auto flex pb-3 border-b-[2px] borde-gray-100">
                <h3 className="font-semibold text-xl select-none cursor-default">
                    Questionário para construção em terreno próprio
                </h3>
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-0 border-b-[1px] border-gray-300">
                <label htmlFor="">Obra já possui projeto aprovado em prefeitura?</label>
                <span data-haserrors={formState?.errors?.approval_property?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.approval_property?.message}</span>
                <Controller
                    control={control}
                    name="approval_property"
                    render={({
                        field,
                    }) => (
                        <span className="w-full flex flex-row items-center gap-4 py-2">
                            <span className="flex flex-row gap-2 items-center">
                                <label htmlFor={field.name + '#1'} className="text-sm font-semibold order-2">Sim</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#1'}
                                    value='1'
                                    checked={INPUT_approval_property_WATCH?.toString() === '1'}
                                    onChange={d => setValue('approval_property', d.value)}
                                />
                            </span>
                            <span className="flex flex-row gap-2 items-center">
                                <label htmlFor={field.name + '#2'} className="text-sm font-semibold order-2">Não</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#2'}
                                    value={'0'}
                                    checked={INPUT_approval_property_WATCH?.toString() === '0'}
                                    onChange={d => setValue('approval_property', d.value)}
                                />
                            </span>
                        </span>
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-0 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor do terreno</label>
                <span data-haserrors={formState?.errors?.terrain_amount?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.terrain_amount?.message}</span>
                <Controller
                    control={control}
                    name="terrain_amount"
                    render={({
                        field,
                    }) => (
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('terrain_amount', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Saldo devedor do terreno</label>
                <span data-haserrors={formState?.errors?.land_outstanding_balance?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.land_outstanding_balance?.message}</span>
                <Controller
                    control={control}
                    name='land_outstanding_balance'
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('land_outstanding_balance', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor total de obra (estimado)</label>
                <span data-haserrors={formState?.errors?.amount_build_estimated?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.amount_build_estimated?.message}</span>
                <Controller
                    control={control}
                    name='amount_build_estimated'
                    render={({
                        field,
                    }) => (
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('amount_build_estimated', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Valor do imóvel pronto para compra e venda (estimado)</label>
                <span data-haserrors={formState?.errors?.property_value_estimated?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.property_value_estimated?.message}</span>
                <Controller
                    control={control}
                    name="property_value_estimated"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('property_value_estimated', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Quanto já gastou na obra</label>
                <span data-haserrors={formState?.errors?.spent_of_project?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.spent_of_project?.message}</span>
                <Controller
                    control={control}
                    name="spent_of_project"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('spent_of_project', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Quanto ainda possui em recursos próprios (fora o que já foi gasto na obra)?</label>
                <span data-haserrors={formState?.errors?.how_much_resources?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.how_much_resources?.message}</span>
                <Controller
                    control={control}
                    name="how_much_resources"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('how_much_resources', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor=""> Renda mensal Informal:</label>
                <span data-haserrors={formState?.errors?.unformal_monthly_income?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.unformal_monthly_income?.message}</span>
                <Controller
                    control={control}
                    name='unformal_monthly_income'
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('unformal_monthly_income', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Renda mensal formal?</label>
                <span data-haserrors={formState?.errors?.formal_monthly_income?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.formal_monthly_income?.message}</span>
                <Controller
                    control={control}
                    name="formal_monthly_income"
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('formal_monthly_income', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor=""> Cidade do terreno</label>
                <span data-haserrors={formState?.errors?.city_of_terrain?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.city_of_terrain?.message}</span>
                <Controller
                    control={control}
                    name='city_of_terrain'
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o nome da cidade do terreno"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Condomínio</label>
                <span data-haserrors={formState?.errors?.build?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.build?.message}</span>
                <Controller
                    control={control}
                    name='build'
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o nome do condomínio"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Endereço completo do terreno com CEP</label>
                <span data-haserrors={formState?.errors?.address_terrain?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.address_terrain?.message}</span>
                <Controller
                    control={control}
                    name="address_terrain"
                    render={({
                        field,
                    }) => (
                        <InputText
                            {...field}
                            placeholder="Digite o endereço do terreno"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                        />
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Possui RGI em seu nome ?</label>
                <span data-haserrors={formState?.errors?.has_rgi?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.has_rgi?.message}</span>
                <Controller
                    control={control}
                    name="has_rgi"
                    render={({
                        field,
                    }) => (
                        <>
                            <span className="flex flex-row gap-2 items-center mb-4">
                                <label htmlFor={field.name + '#1'} className="text-sm font-semibold order-2">Sim, em meu nome</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#1'}
                                    value={1}
                                    defaultChecked={true}
                                    checked={INPUT_has_rgi_WATCH === 1}
                                    onChange={d => setValue('has_rgi', d.value)}
                                />
                            </span>
                            <span className="flex flex-row gap-2 items-center mb-4">
                                <label htmlFor={field.name + '#2'} className="text-sm font-semibold order-2">Não / nome de terceiros</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#2'}
                                    value={2}
                                    checked={INPUT_has_rgi_WATCH === 2}
                                    onChange={d => setValue('has_rgi', d.value)}
                                />
                            </span>
                            <span className="flex flex-row gap-2 items-center mb-4">
                                <label htmlFor={field.name + '#3'} className="text-sm font-semibold order-2"> Nome de PJ</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#3'}
                                    value={3}
                                    checked={INPUT_has_rgi_WATCH === 3}
                                    onChange={d => setValue('has_rgi', d.value)}
                                />
                            </span>
                        </>
                    )}
                />
            </span>

            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor="">Terreno de rua ou condomínio?</label>
                <span data-haserrors={formState?.errors?.terrain_or_build?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.terrain_or_build?.message}</span>
                <Controller
                    control={control}
                    name="terrain_or_build"
                    render={({
                        field,
                    }) => (
                        <>
                            <span className="flex flex-row gap-2 items-center mb-4">
                                <label htmlFor={field.name + '#1'} className="text-sm font-semibold order-2">Rua</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#1'}
                                    value={0}
                                    defaultChecked={true}
                                    checked={INPUT_terrain_or_build_WATCH === 0}
                                    onChange={d => setValue('terrain_or_build', d.value)}                                    
                                /> 
                            </span>
                            <span className="flex flex-row gap-2 items-center mb-4">
                                <label htmlFor={field.name + '#2'} className="text-sm font-semibold order-2">Condomínio</label>
                                <RadioButton
                                    name={field.name}
                                    inputId={field.name + '#2'}
                                    value={1}
                                    checked={INPUT_terrain_or_build_WATCH === 1}
                                    onChange={d => setValue('terrain_or_build', d.value)}
                                />
                            </span>
                        </>
                    )}
                />
            </span>
        </form>
        <button type="submit"
            form="form-acquisition-build"
            className="max-sm:w-full 
                w-64 px-4 py-2
                rounded-lg
                text-white font-semibold 
                bg-[#39a887] hover:bg-[#2c8168] hover:active:bg-[#236652]
                transition-colors
                duration-[0.15s]
                flex flex-row items-center justify-center
                mt-12
            "
        >
            continuar
        </button>
    </>
}